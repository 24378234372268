import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import DefaultFormattedMessage from '../utils/DefaultFormattedMessage'
import { button, container, content, more } from './Consent.module.css'
import LocalLink from './LocalLink'

import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const Consent = () => {
  const maxAge = 60 * 60 * 24 * 300 // cookies blijven 300 dagen staan?
  const [cookies, setCookie] = useCookies([
    'gatsby-gdpr-google-analytics',
    'gatsby-gdpr-google-tagmanager',
    'gatsby-gdpr-facebook-pixel',
    'gatsby-gdpr-tiktok-pixel',
    'consent-setup',
    'accepted',
  ])
  const location = useLocation()

  const consent = cookies['consent-setup']

  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true) // is there be a better way?
  })

  const handleConsent = () => {
    setCookie('consent-setup', 1, { maxAge })
    setCookie('gatsby-gdpr-google-analytics', true, { maxAge }) //moet true zijn voor consent package
    setCookie('gatsby-gdpr-google-tagmanager', true, { maxAge })
    setCookie('gatsby-gdpr-facebook-pixel', true, { maxAge })
    setCookie('gatsby-gdpr-tiktok-pixel', true, { maxAge })
    setCookie('accepted', true, { maxAge })
    initializeAndTrack(location)
  }

  const handleDecline = () => {
    setCookie('consent-setup', 1, { maxAge })
  }

  if (!parseInt(consent) && show) {
    return (
      <div className={container}>
        <p className={content}>
          <DefaultFormattedMessage id="main.consent.p" />
        </p>
        <LocalLink className={more} to="/privacy#cookies">
          <DefaultFormattedMessage id="main.consent.meer" />
        </LocalLink>
        <button className={button} onClick={() => handleConsent()}>
          <DefaultFormattedMessage id="main.consent.accept" />
        </button>
        <button className={button} onClick={() => handleDecline()}>
          <DefaultFormattedMessage id="main.consent.decline" />
        </button>
      </div>
    )
  }

  return null
}

export default Consent
