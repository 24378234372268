module.exports = {
  'main.browser.description':
    'Devine (Digital Design & Development) is een volwaardige bachelor van 3 jaar aan KASK & Conservatorium, de school of arts van HOGENT en Howest in Kortrijk.',
  'main.browser.keywords':
    'Development, Design, Experience Design, Research, KASK, Howest, Kortrijk, Studeren, Digital, Bachelor',
  'main.moreinfo.title': 'Lijkt dit iets voor jou?',
  'main.moreinfo.title2': 'Wil je graag nog wat meer info?',
  'main.moreinfo.p':
    'Schrijf je dan nu in of neem contact op voor meer info We helpen je graag verder!',
  'main.moreinfo.subtitle': 'Infodagen',
  'main.moreinfo.subtitle2': 'Bootcamps',
  'main.moreinfo.button1': 'Hoe inschrijven?',
  'main.moreinfo.button2': 'Neem contact op',
  'main.moreinfo.buttonboot': 'Schrijf je in voor een bootcamp',
  'main.footer.voorwaarden': 'Algemene voorwaarden',
  'main.footer.acyear': 'Academiejaar 2024-2025',
  'main.consent.p':
    'Deze website maakt gebruik van niet strikt noodzakelijke cookies om uw gebruikservaring te verbeteren.',
  'main.consent.meer': 'Meer informatie',
  'main.consent.accept': 'Alles accepteren',
  'main.consent.decline': 'Alles weigeren',
  'main.social.youtube': 'Youtube voor alles in beweging',
  'main.social.facebook': 'Facebook voor events en artikels',
  'main.social.instagram': 'Instagram voor alles wat leeft',
  'main.social.github': 'Duik in onze code op github',
  'main.social.linkedin': 'Devine op Linkedin',
  'main.social.behance': 'Devine op Behance',
  'main.social.twitter': 'Devine Twitter feed',
  'main.social.email': 'Devine Email',
  'main.menu.practical': 'praktisch',
  'main.studentlife.p1': 'Reizen',
  'main.studentlife.p2': 'Lezingen',
  'main.studentlife.p3': 'Stages',
  'main.studentlife.p4': 'Wedstrijden',
  'main.studentlife.subtitle': 'Bij Devine zit je nooit stil',
  'main.studentlife.p6':
    'De eerstejaars trekken er jaarlijks op uit met daguitstappen naar Rotterdam, Brussel of Lille. We verkennen de stad en bezoeken enkele boeiende tentoonstellingen.{br} In je 2e jaar krijg je de kans om de wijde wereld in te trekken op studiereis naar Berlijn, San Francisco, Dublin of misschien wel een totaal andere bestemming. Elk jaar werken docenten een top programma uit om jullie te inspireren en verrassen. Op jouw planning staan bezoeken aan internationale spelers uit onze sector zoals Google, Leap, Oculus, Adobe,... Je krijgt veel kansen om je eigen werk te presenteren en misschien een internationale stageplaats te versieren. In groep verken je de culturele hoogetepunten uit de stad of klim je naar het hoogste punt in Yosemite Park. Wat het ook wordt, het wordt een reis om nooit te vergeten!',
  'main.studentlife.p7':
    'Tijdens je integratieproject in je 2e jaar ga je op internationale uitwisseling naar Nederland. Je bent de hele week te gast op de Hogeschool Rotterdam en werkt samen met de studenten van Cross Media Design. ',
  'main.studentlife.p8':
    'Daarnaast verken je actief het werkveld tijdens één van de vele bedrijfsuitstappen naar agencies en conferenties uit onze sector. Er is elke week wel iets te doen of te beleven in de regio.',
  'main.studentlife.subtitle2': 'Je wordt een expert in je veld',
  'main.studentlife.p9':
    'Tijdens je hele Devine studie carrière komen experts uit de sector aan het woord. Zij kunnen jou als geen ander informeren over het reilen en zeilen als visual designer, experience designer of developer. Deze guestlectures worden gegeven door mensen die als expert gekend staan in hun eigen werkveld. ',
  'main.studentlife.p10':
    'Omgekeerd ga jij buiten de schoolmuren op pad naar inspirerende meet-ups, webinars, conferenties, talks, design weeks,... Er is een ruim aanbod van workshops buiten de lesuren vanuit partners van de school zoals: <budalab>Budalab</budalab>, <designregio>Design Regio Kortrijk</designregio>, Voka, <hangark>Hangar K</hangark>, <flandersdc>Flanders DC</flandersdc>,...',
  'main.studentlife.p11':
    'Je vindt alle activiteiten terug op onze sociale media',
  'main.bump.title': 'Bump festival',
  'main.bump.p1':
    'Studenten, docenten en ons werkveld kijken elk jaar uit naar onze eigen creative conference Bump bij de start van de zomer. Internationale sprekers van graphic designers tot creatieve coders en motion artists delen hun inzichten, workflow en inspiratie op het podium van de Schouwburg in Kortrijk. Sprekers die ons podium mochten betreden zijn: <atkins>Annie Atkins</atkins>, <mcbess>McBess</mcbess>, <neilmendoza>Neil Mendoza</neilmendoza>, <dawkins>Kate Dawkins</dawkins>, <thorp>Ash Thorp</thorp>, <chung>Sougwen Chung</chung>... We sluiten deze dag vol inspiratie telkens af in stijl met een top feestje. See you there!   ',
  'main.bump.button': 'naar de Bump website',
  'main.bump.p2':
    'Schrijf je in op de <b><nieuwsbrief>Bump Festival nieuwsbrief</nieuwsbrief></b> en blijf op de hoogte van alle laatste nieuwtjes',
  'main.form.errorvoorwaarden':
    'Gelieve akkoord te gaan met de algemene voorwaarden.',
  'main.form.errorfamilienaam':
    'Gelieve een correcte familienaam in te vullen.',
  'main.form.errornaam': 'Gelieve een correcte naam in te vullen.',
  'main.form.erroremail': 'Gelieve een correct e-mailadres in te vullen',
  'main.form.naam': 'Voornaam',
  'main.form.familienaam': 'Familienaam',
  'main.form.email': 'E-mailadres',
  'main.form.voorwaarden':
    'Ik ga akkoord met de <voorwaarden>algemene voorwaarden</voorwaarden>',
}
